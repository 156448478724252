import { $API } from "boot/api";

export function storeListingCounts(
  { commit, state, rootState },
  listingsCounts
) {
  commit("setListingCount", listingsCounts);
}

export function storeItems({ commit, state, rootState }, items) {
  commit("setItems", items);
}

export function storePackages({ commit, state, rootState }, packages) {
  commit("setPackages", packages);
}

export function storeLastSynced({ commit, state, rootState }, lastSynced) {
  commit("setLastSynced", lastSynced);
}

export function storeItemTotals({ commit, state, rootState }, itemTotals) {
  commit("setItemTotals", itemTotals);
}

export function storeFilteredItems(
  { commit, state, rootState },
  filteredItems
) {
  commit("setFilteredItems", filteredItems);
}
