export default function () {
  return {
    items: [],
    packages: [],
    listingsCount: [],
    itemTotals: [],
    filteredItems: [],
    lastSynced: "",
  };
}
